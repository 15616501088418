import React, { useEffect, useState } from "react";
import "./HomepageHeader.css"
import PawPrintMask from "./PawPrintMask";
import IntroCard from "./IntroCard";
import FadeInOnScroll from "./FadeInOnScroll";

const animalPhotos: string[] = [
  `${process.env.PUBLIC_URL}/animal-photos/intro-photo-2.jpg`,
  `${process.env.PUBLIC_URL}/animal-photos/intro-photo-3.jpg`,
  `${process.env.PUBLIC_URL}/animal-photos/intro-photo-4.jpg`,
  `${process.env.PUBLIC_URL}/animal-photos/intro-photo-5.jpg`,
  `${process.env.PUBLIC_URL}/animal-photos/intro-photo-6.jpg`,
  `${process.env.PUBLIC_URL}/animal-photos/intro-photo-7.jpg`
];

const carouselInterval: number = 20000
const transitionInterval: number = 2000

const HomepageHeader: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [alternateIndex, setAlternateIndex] = useState(1)
  const [isImage1Active, setIsImage1Active] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsImage1Active((prev) => !prev);

      window.setTimeout(() => {
        setAlternateIndex((prevIndex) => (prevIndex + 2 > animalPhotos.length - 1) ? 1 : prevIndex + 2)
      }, transitionInterval); 

      window.setTimeout(() => {
        setIsImage1Active((prev) => !prev);
      }, carouselInterval / 2); 

      window.setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 2 > animalPhotos.length - 1) ? 0 : prevIndex + 2);
      }, carouselInterval - transitionInterval); 

    }, carouselInterval); // Adjust interval time as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="homepage-header">
      <IntroCard />
      <PawPrintMask />
      <div className="header-img-wrapper">
        <img
        src={animalPhotos[currentIndex]}
        alt="Header Image"
        className={`header-carousel-image ${isImage1Active ? "fade-in" : "fade-out"}`}
        style={{
          transform: isImage1Active
            ? `scale(1.15)`
            : `scale(1)`,
          transition: "transform 20s ease-in-out, opacity 2s ease-in-out",
        }}
        />
        <img
        src={animalPhotos[alternateIndex]}
        alt="Alternate Header Image"
        className={`header-carousel-image ${isImage1Active ? "fade-out" : "fade-in"}`}
        style={{
          transform: isImage1Active
            ? `scale(1.15)`
            : `scale(1)`,
          transition: "transform 20s ease-in-out, opacity 2s ease-in-out",
        }}
        />
      </div>
    </section>
  );
};
  
export default HomepageHeader;