import React from "react";
import "./BioCard.css";
import FadeInOnScroll from "./FadeInOnScroll";

interface BioCardProps{
    imgSrc: string,
    memberName: string,
    memberBio: string,
    reversed?: string
}

const BioCard: React.FC<BioCardProps> = ({ imgSrc, memberName, memberBio, reversed }) => {
    return(
        <FadeInOnScroll>
            <div className={`${reversed} bio-card`}>
                <img src={`${process.env.PUBLIC_URL}/${imgSrc}`} className="bio-card-img"></img>
                <div className="bio-card-text">
                    <h2 className="bio-card-name">{memberName}</h2>
                    <p className="bio-card-bio">{memberBio}</p>
                </div>
                <div className="bio-card-buffer"></div>
            </div>
        </FadeInOnScroll>
    );
};

export default BioCard;