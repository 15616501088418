import React from "react";
import "./DonationsSection.css";
import DonorboxForm from "./DonorBoxForm";
import FadeInOnScroll from "./FadeInOnScroll";

const DonationsSection: React.FC = () => {
    return(
        <div className="donations-main">
            <div className="donations-text-wrapper">
                <FadeInOnScroll>
                <h1 className="donate-header">Donate today to save an animal's life</h1>
                </FadeInOnScroll>
                <FadeInOnScroll>
                <p className="donations-text">We currently partner with three shelters in Tangier, Morocco: Le Sanctuaire de la Faune de Tanger (SFT), Friends of Animals Tangier (FOAT) and The Light of Hope. We support these wonderful shelters, who do incredible rescue work on the ground in Tangier, through financial aid, which is spent on food, vet bills, community education, TNVT programmes, adoption programmes and more.</p>
                </FadeInOnScroll>
                <FadeInOnScroll>
                <p className="donations-text">You can select which of our partner shelters you would like to support when donating, or you can make a general donation which will be used to support the highest priority campaign or initiative at the time.</p>
                </FadeInOnScroll>
                <FadeInOnScroll>
                <p className="donations-text">All of the funds are used transparently and efficiently to save lives and promote animal welfare in Tangier. We are a small, unpaid team who rely on your support to fund these shelters. Thank you for your generosity. </p>
                </FadeInOnScroll>
            </div>
            <DonorboxForm />
        </div>
    );
};

export default DonationsSection;