import React from "react";
import { motion } from "framer-motion";

interface FadeInOnScrollProps {
    children: React.ReactNode;
    duration?: number;
    delay?: number;
    yOffset?: number;
}

const FadeInOnScroll: React.FC<FadeInOnScrollProps> = ({
    children,
    duration = 0.8,
    delay = 0,
    yOffset = 50,
}) => {
    const containerVariants = {
        hidden: { opacity: 0, y: yOffset },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            variants={containerVariants}
            transition={{ duration, delay }}
        >
            {children}
        </motion.div>
    );
};

export default FadeInOnScroll;

