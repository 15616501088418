import React from "react";
import "./DonateButton.css";
import { Link } from 'react-router-dom';

interface DonateButtonProps {
    className?: string;
}

const DonateButton: React.FC<DonateButtonProps> = ({ className }) => {
  return (
    <Link to="/donate">
      <button className={`donate-button ${className}`}>Donate</button>  
    </Link>
  );
};

export default DonateButton;