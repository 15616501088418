import React from 'react';
import './Footer.css'; 
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import SocialMediaButton from './SocialMediaButton';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-contact">
        <h4>Contact Us</h4>
        <div className='email-wrapper'>
          <FaEnvelope color='var(--clr-primary-400)'/>
          <p>salima.animal.trust@gmail.com</p>
        </div>
      </div>
      <div className="footer-lower">
        <div className="footer-navigation">
          <p className="footer-copyright">&copy; Salima Animal Trust Tangier</p>
          <p className="footer-copyright">Registered Charity Number 1210051</p>
          <p className="footer-copyright"><a href="https://www.kualo.com/free-charity-hosting" title="Free Charity Hosting" className='footer-link'>Free Charity Hosting by Kualo</a></p>
          <ul className="footer-nav-list">
            <li className="footer-nav-list-item"><Link to="/">Home</Link></li>
            <li className="footer-nav-list-item"><Link to="/team">Our Team</Link></li>
            <li className="footer-nav-list-item"><Link to="/volunteer">Volunteer with Us</Link></li>
          </ul>
        </div>
        <div className="footer-social">
          <h4>Follow Us</h4>
          <div className="social-buttons">
            <SocialMediaButton
              icon={<FaFacebook size={24}/>} 
              url="https://www.facebook.com/share/17xXDMcsrr/?mibextid=LQQJ4d"
            />
            <SocialMediaButton
              icon={<FaInstagram size={24}/>} 
              url="https://www.instagram.com/salima.animal.trust/profilecard/?igsh=MTZuc2VxcmJ5bWI4YQ=="
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
