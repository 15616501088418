import React from 'react';
import "./SocialMediaButton.css";

interface SocialMediaButtonProps {
  icon: React.ReactNode; 
  url: string;         
}

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({ icon, url }) => {
  const handleClick = () => {
    window.open(url, '_blank');
  };
  
  return (
    <button className="social-media-button" onClick={handleClick}>
      {icon}
    </button>
  );
};

export default SocialMediaButton;