import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomepageHeader from './components/HomepageHeader';
import AboutUsSection from './components/AboutUsSection';
import OurTeamSection from './components/OurTeamSection';
import VolunteerSection from './components/VolunteerSection';
import DonationsSection from './components/DonationsSection';
import Footer from './components/Footer';
import './App.css';
import ScrollToTop from './components/ScrollToTop';

const HomePage: React.FC = () => (
  <>
    <HomepageHeader />
    <AboutUsSection />
  </>
);

const TeamPage: React.FC = () => (
  <>
    <OurTeamSection />
  </>
)

const VolunteerPage: React.FC = () => (
  <>
    <VolunteerSection />
  </>
)

const DonationsPage: React.FC = () => (
  <>
    <DonationsSection />
  </>
)

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
        <Navbar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/volunteer" element={<VolunteerPage />} />
            <Route path="/donate" element={<DonationsPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
