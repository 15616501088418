import React, { useState } from "react";
import "./AboutUsSection.css";
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import FadeInOnScroll from "./FadeInOnScroll";

const AboutUsSection: React.FC = () => {

  const [aboutUsExpanded, setAboutUsExpanded] = useState(false);

  const handleAboutUsExpand = () => {
    setAboutUsExpanded(!aboutUsExpanded);
  };

  return (
    <section className="about-us">
      <div className="about-us-main-wrapper">
        <FadeInOnScroll>
          <h2>About Us</h2>
        </FadeInOnScroll>
        <FadeInOnScroll>
        <p className="about-us-main">
          The Salima Animal Trust Tangier is a UK-registered charity 
          dedicated to alleviating the suffering of street animals in 
          Tangier, Morocco. Our mission is to promote the welfare and 
          care of animals in need by collaborating with local charities
          and shelters to provide essential veterinary services and 
          long-term shelter. We focus on funding the medical treatment, 
          vaccinations, and neutering of stray animals, helping to control 
          the population while ensuring their well-being.
        </p>
        </FadeInOnScroll>
        <p className={`about-us-extra ${aboutUsExpanded ? "section-expanded" : ""}`}>
          In addition to medical support, we prioritise the health and 
          safety of street animals through funding initiatives such as 
          tagging and vaccination programs, especially to prevent rabies. 
          Public education is a key part of our work, and we work to raise 
          awareness about animal welfare and offer unique opportunities for 
          individuals to get involved. These include adopting rescued cats 
          and dogs into homes in the UK and EU, sponsoring animals living in 
          shelters in Tangier, and volunteering with our partner charities, 
          whether you're an animal lover or a vet student seeking hands-on 
          experience. For more information, visit our volunteering page and follow us on social media.
        </p>
        <FadeInOnScroll>
        <button 
        className="read-more-button"
        onClick={handleAboutUsExpand}
        >{aboutUsExpanded ? "Read Less" : "Read More"}</button>
        </FadeInOnScroll>
        <FadeInOnScroll>
        <p className="about-us-transparency">
          The Salima Animal Trust Tangier is committed to transparency, ensuring 
          that every donation is used for its intended purpose to improve the lives 
          of animals in Tangier.
        </p>
        </FadeInOnScroll>
      </div>
      <div className="about-us-img-container">
        <img
          src={`${process.env.PUBLIC_URL}/animal-photos/intro-photo-1.jpg`}
          alt="Header Image"
          className="about-us-img"
        />
      </div>
    </section>
  );
};

export default AboutUsSection;