import React, { useEffect } from "react";

const DonorboxForm: React.FC = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://donorbox.org/widget.js";
        script.setAttribute("paypalExpress", "true");
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Cleanup script when component unmounts
        };
    }, []);

    return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
            <iframe
                src="https://donorbox.org/embed/saving-animals-in-tangier?language=en-us"
                name="donorbox"
                allow="payment"
                {...{ allowpaymentrequest: "true" } as any}
                seamless={true}
                frameBorder="0"
                scrolling="no"
                style={{
                    height: "900px",
                    width: "100%",
                    maxWidth: "500px",
                    minWidth: "250px",
                    maxHeight: "none!important",
                }}
            />
        </div>
    );
};

export default DonorboxForm;
