import React from "react";
import './PawPrintMask.css';

const PawPrintMask: React.FC = () => {
    const toeColor = "#e3caa4"

    return (
      <div className="paw-print-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 480 480"
          preserveAspectRatio="xMidYMid meet"
          className="paw-mask-svg"
        >

          <defs>
            <mask id="mask-clip" maskUnits="userSpaceOnUse" maskContentUnits="userSpaceOnUse">
              <rect width="100%" height="100%" fill="white"/>
              <path d="M2520 2799 c-90 -10 -116 -17 -174 -43 -28 -13 -56 -25 -62 -27 -26
              -9 -183 -136 -197 -159 -9 -14 -24 -35 -35 -46 -11 -12 -32 -44 -46 -71 -39
              -78 -45 -205 -37 -713 3 -145 2 -150 -67 -316 -61 -145 -64 -305 -10 -473 28
              -88 49 -124 125 -219 118 -147 306 -241 496 -246 82 -3 142 16 293 89 85 42
              305 199 397 285 95 88 217 214 267 275 30 37 60 73 65 79 6 6 24 29 40 51 17
              22 35 45 41 52 6 6 26 35 45 65 18 29 39 59 46 66 19 20 121 205 168 306 93
              200 99 472 12 623 -6 12 -34 47 -60 77 -57 66 -172 126 -277 145 -36 7 -81 16
              -100 21 -32 8 -68 15 -205 41 -62 12 -121 25 -330 74 -60 14 -126 29 -145 34
              -124 34 -170 39 -250 30z" fill="black" transform="translate(0.000000, 
              400.000000) scale(0.100000,-0.100000)" />
            </mask>
          </defs>
          <rect className="mask-rect" width="100%" height="100%" fill="#fefaf6" mask="url(#mask-clip)" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 480 480"
          preserveAspectRatio="xMidYMid meet"
          className="paw-toes-svg"
        >
          <g>
            <path d="M 1907 4219 C 1855 4207 1789 4160 1751 4108 C 1688 4023 1673.229 
            3846.57 1684 3726 C 1703.793 3504.432 1907 3265 1950 3221 C 2070 3101 2212 
            3037 2346 3041 C 2527 3047 2644 3208 2639 3443 C 2636 3599 2562 3798 2452 
            3945 C 2294 4155 2084 4261 1907 4219 Z" fill={toeColor} transform="translate(0.000000, 
            400.000000) scale(0.100000,-0.100000)"/>
            <path d="M 3384 4014 C 3297.89 4018.797 3168 3979 3098 3927 C 2964 3828 2890 
            3672 2889 3490 C 2889 3362 2923.784 3236.635 3004 3097 C 3066.238 2988.661 
            3202 2899 3328 2864 C 3403 2844 3539.285 2857.687 3592.296 2868.593 C 3719.057 
            2894.672 3789.456 3000.75 3815.834 3031.536 C 3861.293 3084.59 3891 3175 3905 
            3237 C 3929 3341 3923.014 3451.004 3886 3595 C 3842.265 3765.144 3688 3899 3625 
            3939 C 3565 3977 3468.161 4009.312 3384 4014 Z" fill={toeColor} 
            transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"/>
            <path d="M 973 3519 C 835.608 3488.542 738 3362.237 737 3202 C 736.108 3059.003 
            845.541 2797.556 1110 2580 C 1260.833 2455.918 1472.695 2424.985 1611 2463 C 1769.98 
            2506.698 1833.221 2642.548 1814 2826 C 1790.638 3048.973 1574.983 3272.91 1477 3365 
            C 1277.478 3552.522 1125.664 3552.844 973 3519 Z" fill={toeColor} transform="translate(0.000000, 
            400.000000) scale(0.100000,-0.100000)"/>
            <path d="M 863 2305 C 708.681 2283.783 589 2149 559 2102 C 477 1973 486.027 
            1789.955 537 1631 C 569.398 1529.967 681.599 1426.401 730 1391 C 908.332 1260.568 
            1223 1253 1304 1280 C 1464 1335 1600.888 1448.262 1624.467 1595.317 C 1638.467 
            1682.63 1644.682 1733.862 1595.583 1885.834 C 1535.974 2039.491 1472.561 2113.134 
            1375 2184 C 1248.274 2276.052 1004.307 2324.428 863 2305 Z" fill={toeColor} 
            transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"/>
          </g>
        </svg>
      </div>
    );
  };
  
  export default PawPrintMask;