import React from "react";
import "./IntroCard.css";
import DonateButton from "./DonateButton";

const IntroCard: React.FC = () => {
  
      return (
        <div className="intro-card">
            <h2 className="intro-card-title">
                <span className="line1 main-wrap">The <span className="accent-color">Salima</span> Animal</span>
                <span className="line2 main-wrap">Trust Tangier</span>
                <span className="line1 alternate-wrap">The <span className="accent-color">Salima</span></span>
                <span className="line2 alternate-wrap">Animal Trust Tangier</span>
            </h2>
            <DonateButton className='header-donate-button'/>
        </div>
      );
    };
    
    export default IntroCard;