import React from "react";
import "./VolunteerSection.css";
import FadeInOnScroll from "./FadeInOnScroll";

const VolunteerSection = () => {
    return(
        <section>
            <div className="uk-volunteering">
                <h1>Volunteering</h1>
                <div className="uk-volunteering-header">
                <FadeInOnScroll>
                    <h2>UK Volunteer Positions</h2>
                </FadeInOnScroll>
                <FadeInOnScroll>
                    <p className="uk-vol-p">We are recruiting two volunteers to join our team and help grow The Salima Animal Trust Tangier. These roles are open to anyone who is 18+, and there are no restrictions on your location as long as you are able to answer messages during UK working hours. Please note these are unpaid volunteering positions.</p>
                    <p className="uk-vol-p">Check out the details below and, if you are interested in applying, please email salima.animal.trust@gmail.com. </p>
                </FadeInOnScroll>
                </div>
                <FadeInOnScroll>
                <div className="job-card-wrapper">
                    <div className="job-card">
                        <h3>Social Media and Marketing Volunteer</h3>
                        <h4>The Role</h4>
                        <ul>
                            <li>Grow our Instagram and Facebook + Launch TikTok</li>
                            <li>Engage with supporters on social media</li>
                            <li>Launch and maintain our newsletter</li>
                        </ul>
                        <h4>Requirements</h4>
                        <ul>
                            <li>Passion for animals</li>
                            <li>Social media knowledge</li>
                            <li>Creative & self-motivated</li>
                            <li>~4-6 hrs/week, fully remote</li>
                        </ul>
                        <h4>Perks</h4>
                        <ul>
                            <li>Build your social media portfolio</li>
                            <li>Help a meaningful cause </li>
                            <li>Visit our partner charities in Tangier with food & stay included!</li>
                        </ul>
                    </div>
                    <div className="job-card">
                        <h3>Grant Fundraising and Supporter Engagement Volunteer</h3>
                        <h4>The Role</h4>
                        <ul>
                            <li>Identify and apply for suitable grants on a monthly basis</li>
                            <li>Catalogue donations in excel and ensure accounts are kept up to date </li>
                        </ul>
                        <h4>Requirements</h4>
                        <ul>
                            <li>Passion for animals</li>
                            <li>Experience using Excel</li>
                            <li>Strong writing and communication skills </li>
                            <li>~3-4 hrs/week, fully remote</li>
                        </ul>
                        <h4>Perks</h4>
                        <ul>
                            <li>Build your skills in account management and fundraising</li>
                            <li>Help a meaningful cause </li>
                            <li>Visit our partner charities in Tangier with food & stay included!</li>
                        </ul>
                    </div>
                </div>
                </FadeInOnScroll>

            </div>
            <div className="volunteering-morocco-header">
                <img src={`${process.env.PUBLIC_URL}/sanctuary-photo.jpeg`} className="sanctuary-photo"></img>
                <div className="vol-header-text-wrapper">
                <FadeInOnScroll>
                    <h2>Volunteering in Morocco</h2>
                </FadeInOnScroll>
                <FadeInOnScroll>
                    <p>
                        We are delighted to be able to offer volunteering opportunities at our partner charity SFT in Tangier. If you are interested in a fantastic and affordable trip where you can really make a difference, please read the information below and fill in the form. A member of our team will get back to you as soon as possible.
                    </p>
                </FadeInOnScroll>
                </div>
            </div>
            <div className="vol-section-main-wrapper">
                <div className="vol-section-main">
                <FadeInOnScroll>
                    <div className="vol-text-col">
                        <h4>Volunteering in Tangier: What to Expect</h4>
                        <p>
                            We are currently able to offer volunteering with one of our partner charities in Tangier: SFT. At SFT, volunteers play a vital role in the lives of the animals. Your primary responsibility will be socialising with the dogs and cats, helping them build trust and confidence in preparation for adoption. Many of the animals are former strays or have experienced trauma, so your care and attention are essential to their well-being. Volunteering involves spending quality time with the animals - playing fetch with energetic dogs, offering quiet companionship to shy or senior animals, or delighting in the playful antics of cats and kittens. 
                        </p>
                        <p>
                            You'll also help with daily tasks like feeding, cleaning, walking dogs in the countryside, and maintaining the sanctuary. SFT is home to over 900 dogs, cats, donkeys, pigs, etc., with the majority being dogs, and it a must that you are confident being surrounded by these lovely animals!
                        </p>
                        <p>
                            Accommodation includes a cosy, basic volunteer hut with cooking facilities. Volunteers are responsible for their own meals and keeping the space tidy. The sanctuary is in a tranquil rural setting, ideal for peaceful walks with or without a furry friend. It is very remote, without easy access to city amenities, so this is something to take into account, though you will of course have opporunities to buy food and drinks. The minimum duration for volunteering is 2 weeks, but SFT often get volunteers that stay for months. Tangier is also a great place to begin exploring the rest of Morocco from, as it has good transport links to other cities and is at the most northern point of the country. </p>
                        <p>
                            By choosing to volunteer with SFT, you will be asked to agree that The Salima Animal Trust and SFT will not be held liable for any injuries, damages, losses or other incidents that may occur during your volunteering. Your participation is entirely voluntary and at your own risk - to mitigate the common risks of working with animals you must excercise good judgement and behave in an appropriate manner at all times.
                        </p>
                    </div>
                </FadeInOnScroll>
                <FadeInOnScroll>
                    <div className="vol-text-col">
                        <h4>Tips for Volunteers</h4>
                        <ul>
                            <li>
                                Health and Safety: Bring medications, first aid supplies, and ensure you have appropriate travel insurance. Ensure that you are up to date with the recommended vaccines (visit https://travelhealthpro.org.uk/country/154/morocco#Vaccine_Recommendations to find out more). As you will be working with animals, a rabies vaccine is also strongly recommended, although not essential. While all the animals at the sanctuary are vaccinated against rabies, SFT conduct daily rescues of stray animals, which only volunteers who are vaccinated against rabies are allowed to be part of.
                            </li>
                            <li>
                                Packing Essentials: Sunscreen, a sleeping bag, durable clothing, and personal items like toiletries and a towel. Extras like a flashlight, charger pack, and snacks from home can make your stay more comfortable. Please ensure that you do not wear nice clothes in the sanctuary as they will not stay nice for very long! Old trousers and tshirts are best. When choosing your clothes, especially for outside the sanctuary, consider that Morocco is a Muslim country so dressing conservatively (shoulders, elbows, and knees covered) is considered respectful.
                            </li>
                            <li>
                                Climate: Morocco's Mediterranean weather varies; check the forecast for your stay. While summers are consistently hot and dry, the other seasons can be cold and rainy. If you are visiting during the late spring, summer, or early autumn, consider bringing a swimming costume if you would like to go to the beach!
                            </li>
                        </ul>
                        <p>
                            We welcome volunteers from all backgrounds but are especially excited to offer placements to veterinary and zoology students. If you have any questions about volunteering, feel free to email salima.animal.trust@gmail.com
                        </p>
                    </div>
                </FadeInOnScroll>
                </div>
                <p className="vol-bottom-text">Please fill in our form <a href="https://docs.google.com/forms/d/e/1FAIpQLSdCXvLZIvsvnhFJrD0kHiRaOLKuy4b9sh3LFqaUT2AxJZ6hvA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" className="bold-text">here</a> if you are interested in volunteering in Morocco</p>
            </div>
        </section>
    );
};

export default VolunteerSection;