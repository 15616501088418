import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import DonateButton from './DonateButton';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      {/* Logo Section */}
      <div className="navbar-logo">
        <img src={`${process.env.PUBLIC_URL}/salima-logo.png`} alt="Charity Logo" className="logo" />
      </div>
      
      {/* Hamburger Button for Narrow Screens */}
      <button className="nav-menu-toggle" onClick={handleMenuToggle}>
        {isMenuOpen ? '✕' : '☰'}
      </button>

      <div className={`navbar-content ${isMenuOpen ? 'active' : ''}`}>
        {/* Navigation Links */}
        <ul className="navbar-list">
          <li className="navbar-list-item"><Link to="/">Home</Link></li>
          <li className="navbar-list-item"><Link to="/team">Our Team</Link></li>
          <li className="navbar-list-item"><Link to="/volunteer">Volunteer with Us</Link></li>
        </ul>
        
        {/* Donate Button */}
        <DonateButton className='nav-donate-button'/>
      </div>
    </nav>
  );
};

export default Navbar;
