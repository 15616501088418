import React from "react";
import "./OurTeamSection.css";
import BioCard from "./BioCard";
import { Link } from "react-router-dom";
import FadeInOnScroll from "./FadeInOnScroll";

const OurTeamSection: React.FC = () => {
    return(
        <section className="our-team-section">
            <h1>Our Team</h1>
            <FadeInOnScroll>
            <p className="our-team-p">The Salima Animal Trust Tangier is run by a team of volunteer trustees who are committed to improving animal welfare in Morocco</p>
            </FadeInOnScroll>
            <div className="bio-card-wrapper">
                <BioCard 
                imgSrc="clara-team-photo.jpeg" 
                memberName="Clara" 
                memberBio="Clara has extensive experience in animal welfare, fundraising, and nonprofit management, working with national charities such as The Blue Cross and Celia Hammond Animal Trust. She has successfully led major donor fundraising campaigns, hosted community events, and is passionate about advancing animal welfare initiatives." 
                />
                <BioCard 
                imgSrc="will-team-photo.jpeg" 
                memberName="Will" 
                memberBio="Will comes from a consultancy and finance background, and has used skills developed there to support a range of charitable initiatives. He is passionate about providing transparent financial aid to animal welfare initiatives and works with our partner charities to maximise the efficacy of every donation."
                reversed="reversed" 
                />
                <BioCard 
                imgSrc="michele-team-photo.jpeg" 
                memberName="Michele" 
                memberBio="Michele has extensive volunteering experience in Cambodia, Nepal, Greece, South Dakota, Indonesia, Romania, Mongolia, India, and Morocco, the last five of which she devoted her time to the wellbeing of animals. She has also shared her learning and experience in CBT and counselling, volunteering with several homeless charities." 
                />
                <BioCard 
                imgSrc="vanessa-team-photo.jpeg" 
                memberName="Vanessa" 
                memberBio="Vanessa studied animal care and nursing and has volunteered around the world at animal charities in
                India, the Himalayas, the Amazon rainforest, Peru, Europe,Thailand and America. She is a dog lover and has a beautiful German Shepherd!" 
                reversed ="reversed"
                />
            </div>
            <FadeInOnScroll>
            <p>If you are passionate about animals and want to help us with our work, visit our <Link className="accent-color vol-link" to="/volunteer">volunteering</Link> page</p>
            </FadeInOnScroll>
        </section>
    );
};

export default OurTeamSection;